export interface TransactionOptions {
  programId?: string;
  offerId?: string;
  last?: any;
}

const transactionStatuses = [
  'cleared',
  'auth',
  'pending',
  'qualified',
  'refund',
  'rejected',
] as const;

export type TransactionStatus = typeof transactionStatuses[number];

interface TransactionOffer {
  cashback: number;
  message: string[];
  id: string;
  performanceFee: number;
  qualificationDate: string;
  qualified: boolean;
}

export function transformToTransactionOffer(data: any): TransactionOffer {
  return {
    cashback: data.cashback,
    message: data.message || [],
    id: data.id,
    performanceFee: data.performanceFee,
    qualificationDate: data.qualificationDate,
    qualified: data.qualified,
  };
}

interface TransactionBrand {
  id?: string;
  logoURL?: string;
  metadata?: string;
  name?: string;
}

export interface Transaction {
  accountId: string;
  address: string;
  amount: number;
  auth: boolean;
  brand: TransactionBrand;
  cardId: string;
  city: string;
  cleared: boolean;
  countryCode: string;
  created: string;
  currency: string;
  locationId: string;
  date: string;
  id: string;
  lastNumbers: string;
  merchantId: string;
  offer?: TransactionOffer;
  postcode: string;
  programId: string;
  qualificationDate: string;
  status?: TransactionStatus;
}

function getTransactionStatus(data: any): TransactionStatus {
  if (data.amount < 0) return 'refund';

  return data.cleared ? 'cleared' : 'auth';
}

export function transformToTransaction(data: any): Transaction {
  const transaction: Transaction = {
    id: data.id,
    accountId: data.accountId,
    address: data.address || data.location?.address,
    amount: data.amount,
    auth: data.auth,
    brand: data.brand,
    cardId: data.cardId || data.card?.id,
    city: data.city || data.location?.city,
    cleared: data.cleared,
    created: data.created,
    countryCode: data.countryCode || data.location?.countryCode,
    currency: data.currency,
    date: data.date || data.datetime,
    lastNumbers: data.lastNumbers || data.card?.lastNumbers,
    merchantId: data.merchantId || data?.identifiers?.MID,
    locationId: data.location?.id || data.locationId,
    offer: data.offer ? transformToTransactionOffer(data.offer) : undefined,
    postcode: data.postcode || data.location?.postcode,
    programId: data.programId,
    qualificationDate: data.qualificationDate || data.offer?.qualificationDate,
    status: getTransactionStatus(data),
  };

  return transaction;
}
