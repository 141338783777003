import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import moment from 'moment';

import {
  clear,
  clearTransaction,
  getTransactions,
  getTransactionsCount,
} from '../../store/transactions/transactions-actions';
import {
  filterTransactions,
  clearTransactions,
} from '../../store/filters/filters-actions';
import { selectHideLastNumbers } from '../../store/account/account-selectors';
import { Transaction } from '../../store/transactions/transactions-model';
import * as OfferActions from '../../store/offers';
import { CardCell } from './styled';
import Ellipsis from '../../components/ellipsis';
import useRawDetail from '../../components/raw-detail/hooks/use-raw-detail';
import FilterBox from '../../components/filter-box';
import useSelectedProgram from '../../hooks/use-selected-program';
import { AmountCell } from '../../components/tables/styled/helpers';
import Header from '../../components/header';
import { toDecimal } from '../../utils/transform';
import { DOUBLE_MINUS } from '../../utils/special-character';
import InfiniteScrollTable from '../../components/tables/InfiniteScrollTable';
import OfferPopover from './components/OfferPopover';
import { StatusTag } from './components/TransactionTag';
import TransactionsExport from './components/TransactionsExport';
import useSelectedRows from '../../hooks/use-selected-rows';
import useTransactionStream, {
  SelectTransactionCheck,
} from '../../hooks/use-transaction-stream';
import useCopyWithNotification from '../../hooks/use-copy-with-notification';
import SelectionIndicator from '../../components/selection-indicator';
import memoNoProps from '../../utils/memo-no-props';
import { Actions } from '../../components/tables/base/Row';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const Transactions = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { selectedProgram } = useSelectedProgram();
  const { hasProgramTransactionStream } = useTransactionStream();
  const copyWithNotification = useCopyWithNotification();
  const {
    transactions,
    lastTransaction,
    loading,
    loadingOffers,
    transactionsCount,
    filters,
    activeFilters,
  } = useAppSelector(
    state => ({
      isLive: state.live,
      transactions: state.transactions.transactions,
      lastTransaction: state.transactions.lastTransaction,
      loading: state.transactions.loading,
      loadingOffers: state.offers.loading,
      transactionsCount: state.transactions.transactionsCount,
      filters: state.filters.transactions,
      activeFilters: state.filters.activeFilters.transactions,
    }),
    shallowEqual,
  );
  const hideLastNumbers = useAppSelector(selectHideLastNumbers);
  const { rawDetail, openRawDetail } = useRawDetail('transactions');
  const [clearing, setClearing] = useState(false);
  const selectedRows = useSelectedRows<Transaction>(
    transaction => transaction.id,
    transactions,
  );

  const fetchTransactions = useCallback(() => {
    if (selectedProgram?.id) {
      dispatch(getTransactions({ programId: selectedProgram?.id }));
      dispatch(getTransactionsCount({ programId: selectedProgram?.id }));
    }
  }, [dispatch, selectedProgram]);

  useEffect(
    () => () => {
      dispatch(clear());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions, filters]);

  useEffect(() => {
    if (clearing && !loading) {
      fetchTransactions();
      setClearing(false);
    }
  }, [clearing, fetchTransactions, loading]);

  useEffect(() => {
    if (!hasProgramTransactionStream) dispatch(OfferActions.getAllOffers());
  }, [dispatch, hasProgramTransactionStream]);

  function loadMoreTransactions() {
    if (lastTransaction && !loading)
      dispatch(
        getTransactions({
          programId: selectedProgram?.id,
          last: lastTransaction,
        }),
      );
  }

  const rows = transactions.map(transaction => {
    const {
      address,
      amount,
      brand,
      cleared,
      currency,
      date,
      id,
      lastNumbers,
      status,
    } = transaction;

    const safeAmount = amount || 0;

    const safeAmountFormatted = Number.isInteger(safeAmount)
      ? safeAmount
      : toDecimal(safeAmount);

    const safeLastNumbers = hideLastNumbers ? null : lastNumbers ? (
      <CardCell>{lastNumbers}</CardCell>
    ) : (
      DOUBLE_MINUS
    );

    const contents = [
      <Ellipsis>{moment(date).format('MMM D, YYYY HH:mm:ss')}</Ellipsis>,
      <Ellipsis>{brand?.name || DOUBLE_MINUS}</Ellipsis>,
      <SelectTransactionCheck>
        <Ellipsis>{address || DOUBLE_MINUS}</Ellipsis>
      </SelectTransactionCheck>,
      safeLastNumbers,
      status ? <StatusTag status={status} /> : DOUBLE_MINUS,
      <OfferPopover transaction={transaction} loading={loadingOffers}>
        <AmountCell data-testid="amount">
          <span>{safeAmountFormatted}</span>{' '}
          <span>{currency.toUpperCase()}</span>
        </AmountCell>
      </OfferPopover>,
    ];

    const actions: Actions = [
      { label: t('actions.viewRaw'), callback: openRawDetail(id) },
      {
        label: t('actions.copyId'),
        callback: () => copyWithNotification(id),
      },
    ];

    if (!cleared)
      actions.push({
        label: t('transactions.label.clear'),
        callback: () =>
          Modal.confirm({
            title: t('transactions.clear.title'),
            content: t('transactions.clear.message'),
            onOk: () => {
              dispatch(clearTransaction(id));
              setClearing(true);
            },
            okButtonProps: { danger: true },
            okText: t('yes'),
            maskClosable: true,
            icon: null,
          }),
      });

    return {
      contents,
      actions,
      onClick: openRawDetail(id),
      selected: selectedRows.isSelected(transaction),
      onSelect: selectedRows.onSelect(transaction),
    };
  });

  return (
    <>
      <InfiniteScrollTable
        rows={rows}
        minWidth={1000}
        columns={[
          {
            heading: t('transactions.label.date'),
            size: 0.2,
            sortedBy: 'unset',
          },
          { heading: t('transactions.label.brand'), size: 0.2 },
          {
            heading: hasProgramTransactionStream
              ? null
              : t('transactions.label.location'),
            size: hasProgramTransactionStream ? 0 : 0.2,
          },
          {
            heading: hideLastNumbers ? null : t('transactions.label.card'),
            size: hideLastNumbers ? 0 : 0.1,
            align: 'left',
          },
          {
            heading: t('transactions.label.status'),
            size: 0.12,
            align: 'left',
          },
          {
            heading: t('transactions.label.amount'),
            size: 0.2,
            align: 'left',
            style: { marginRight: '28px' },
          },
        ]}
        onBottom={loadMoreTransactions}
        activeFilters={activeFilters}
        total={transactionsCount}
        loading={loading}
        emptyText={
          activeFilters
            ? t('transactions.emptyFiltered')
            : t('transactions.empty')
        }
        onReload={() => {
          dispatch(clear());
          fetchTransactions();
        }}
        onSelectAll={selectedRows.onSelectAll(transactions)}
        topContent={
          <>
            <Header
              heading={t('transactions.title')}
              bottomLeft={
                <FilterBox
                  filterName="transactions"
                  filtersToHide={
                    hasProgramTransactionStream
                      ? ['qualifiedTransactions', 'brandId', 'schemes']
                      : ['schemes']
                  }
                  action={filterTransactions}
                  clear={clearTransactions}
                />
              }
              bottomRight={<TransactionsExport />}
            />
            <SelectionIndicator
              count={selectedRows.count}
              i18nKey="transactions.selected"
              actions={<TransactionsExport selectedIds={selectedRows.items} />}
            />
          </>
        }
      />
      {rawDetail}
    </>
  );
};

export default memoNoProps(Transactions);
