import { AmountOperator, TransactionsFilter } from '../filters/filters-reducer';
import { Transaction } from '../transactions/transactions-model';

export default function transactionMatchesFilter(
  transaction: Transaction,
  filters: TransactionsFilter = {},
): boolean {
  if (!filters) return true;

  if (filters.amount)
    switch (filters.amount.operator) {
      case AmountOperator.LESS:
        if (transaction.amount >= filters.amount.number) return false;
        break;
      case AmountOperator.GREATER:
        if (transaction.amount <= filters.amount.number) return false;
        break;
      case AmountOperator.EQUAL:
        if (transaction.amount !== filters.amount.number) return false;
        break;
      default:
        break;
    }

  return !(
    filters.transactionStatus &&
    transaction.status !== filters.transactionStatus
  );
}
